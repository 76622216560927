// 因为 useNavigation() 不能在类组件中使用，所以需要通过 context 来封装一下

import React, { createContext, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

export const NavigationContext = createContext();

export function NavigationProvider({ children }) {
  const navigate = useNavigate();
  const value = { navigate };

  return (
    <NavigationContext.Provider value={value}>
      {children}
    </NavigationContext.Provider>
  );
}

export function useNavigation() {
  return useContext(NavigationContext);
}
