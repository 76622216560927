import React from "react";
import { NavigationContext } from "../NavigationContext";
import { jump,jumpWithRouter } from "../util/jump";

class ArticleImage extends React.Component {
    static contextType = NavigationContext; // 设置 contextType 以访问 navigate

    handleClick = (data) => {
        const { navigate } = this.context;
        // jump (data.type, data.link, data.id, navigate);
        jumpWithRouter(data.type, data.link, data.id, data.title ,navigate, 0 ,"首页");
    }
    render () {
        const { data } = this.props;
        return (
            <div onClick={()=>this.handleClick(data)}>
                <img src={data.img} />
            </div>
        )
    }

}
export default ArticleImage;