import React from "react";
import SearchList from "../components/SearchList";
import "../styles/articles.css"
import { useLocation } from "react-router-dom";

const Search = (props) => {
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const keyword = urlParams.get('keyword');
    return (
        <div className="article-container">
            {<div className="article-search">搜索："{keyword}"</div>}
            <SearchList />
        </div>
    );
}
export default Search;