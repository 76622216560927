import React from "react";
import { NavigationContext } from "../NavigationContext";
import { jump } from "../util/jump";

class CouncilImage extends React.Component {
    // static contextType = NavigationContext; // 设置 contextType 以访问 navigate

    handleClick = (data) => {
        const { navigate } = this.context;
        jump (data.type, data.link, data.id, navigate);
    }
    render () {
        const { data } = this.props;
        return (
            <div onClick={()=>this.handleClick(data)}>
                <img  style={{ maxWidth: '100px' }} src={data.img} />
            </div>
        )
    }

}
export default CouncilImage;