import React from "react";
import { NavigationContext } from "../NavigationContext";
import { jump } from "../util/jump";
import '../styles/expert.css';

class ExpertGroup extends React.Component {
    static contextType = NavigationContext; // 设置 contextType 以访问 navigate

    handleClick = (data) => {
        const { navigate } = this.context;
        jump (data.type, data.link, data.id, navigate);
    }
    render () {
        const { data } = this.props;
        console.log(data);
        return (
            <div >
                <div className='expert-list' onClick={()=>this.handleClick(data)}>
                    <img className='head-img' src={data[0].img} />
                        <div className='expert-text'>
                            <div className='expert-name'>{data[0].name}</div>
                            <div className='expert-position'>{data[0].position}</div>
                            <div className='expert-content'>{data[0].content}</div>
                        </div>
                    </div>
                    <div className='expert-list' onClick={()=>this.handleClick(data)}>
                    <img className='head-img' src={data[1].img} />
                        <div className='expert-text'>
                            <div className='expert-name'>{data[1].name}</div>
                            <div className='expert-position'>{data[1].position}</div>
                            <div className='expert-content'>{data[1].content}</div>
                        </div>
                    </div>
            </div>
        )
    }

}
export default ExpertGroup;