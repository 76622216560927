import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '../styles/banner.css';
import { jump , jumpWithRouter} from '../util/jump';
import { NavigationContext } from "../NavigationContext";

class Banner extends React.Component {

    constructor(props) {
        super(props);
        console.log("banner constructor");
    }

    static contextType = NavigationContext; // 设置 contextType 以访问 navigate

    handleOnClick = (banner) => {
      const {navigate} = this.context;
        //  jump(banner.type, banner.link, banner.id,navigate);
        jumpWithRouter(banner.type, banner.link, banner.id, banner.title ,navigate, 0,"首页");
    };

    componentDidMount() {
        console.log("banner component Mounted");
    };

  render() {
    const banners  = this.props.banners;
    console.log("banners = ",banners);

    // 检查 banners 是否为数组
    if (!Array.isArray(banners)) {
      return <div>Error: banners is not an array</div>;
    }

    return (
      <Carousel
        showArrows={true}
        autoPlay={true}
        infiniteLoop={true}
        interval={3000}
        showThumbs={false}
      >
        {banners.map((banner) => (
          <div key={banner.id} onClick={() => this.handleOnClick(banner)}>
            <img src={banner.img} />
          </div>
        ))}
      </Carousel>
    );
  }
}

export default Banner;
