import React, { useContext } from "react";
import { HomeDataContext } from '../context'
const Footer = () => {

    const homedata = useContext(HomeDataContext)
    const data = homedata?.footer;

    const contactArray = [];
    if (data?.contact?.phone?.length > 0) {
        const replacedStr = data?.contact?.phone.replace(/，/g, ',');
        const arr = replacedStr.split(',')
        contactArray.push({ title: '电话:' })
        arr.forEach(element => {
            contactArray.push({ title: element })
        });
    }
    if (data?.contact?.email?.length > 0) {
        contactArray.push({ title: '邮箱:' })
        contactArray.push({ title: data?.contact?.email })
    }
    if (data?.contact?.address?.length > 0) {
        contactArray.push({ title: '地址:' })
        contactArray.push({ title: data?.contact?.address })
    }

    const [part1, part2] = splitArrayEqually(data?.friendship_link);

    return (
        <footer style={{
            background: '#3F6600',
        }} >
            <div style={{
                width: 1000,
                display: 'flex',
                justifyContent: 'space-between',
                padding: '72px 0px',
                margin: '0 auto',
            }}>
                {
                    TextContent('友情链接', part1)
                }
                {
                    TextContent(' ', part2)
                }
                {
                    TextContent('联系方式', contactArray)
                }
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 16,
                        alignItems: 'center'
                    }}
                >
                    <img style={{
                        width: 346
                    }} src={data?.qrcode.logo}></img>
                    <img style={{
                        width: 264
                    }} src={data?.qrcode?.qr_code}></img>
                </div>
            </div>
            <div
                style={{
                    background: '#254000',
                    color: 'white',
                    fontSize: 15,
                    lineHeight: '21px',
                    textAlign: "center",
                    padding: '24px 0px',
                }}
            >
                Copyright 2024 SurveyPaid. All Rights Reserved
            </div>
        </footer >
    )
}

function splitArrayEqually(array) {
    // 计算中间索引
    if (!array || array?.length === 0) {
        return [[], []]
    }
    const midIndex = Math.ceil(array.length / 2);

    // 创建两个子数组
    const firstPart = array.slice(0, midIndex);
    const secondPart = array.slice(midIndex);

    return [firstPart, secondPart];
}
const TextContent = (title, subTitle) => {
    console.info(title, subTitle)

    return (
        <div style={{
            display: 'flex',
            color: 'white',
            fontSize: '15px',
            lineHeight: '30px',
            flexDirection: 'column',
        }}>
            <div style={{
                fontWeight: 'bold',
                paddingBottom: 8,
                minHeight: 38
            }}>
                {title}
            </div>
            <div style={{
                maxWidth: 250
            }}>
                {
                    subTitle?.map?.(
                        (item, index) => {
                            if (item.link) {
                                return <div key={index} onClick={() => { window.open(item.link) }} style={{
                                    cursor: item.link.length ? 'pointer' : 'default',
                                }}>
                                    {item.title}
                                </div>
                            }
                            return <div key={index}>
                                {item.title}
                            </div>
                        }
                    )
                }
            </div>
        </div>
    )
}

export default Footer;