import React from "react";
import { apiExpertListUrl, fetchAndProcessData } from "../api/Api";
import { NavigationContext } from "../NavigationContext";
import { jump,jumpWithRouter ,jumpWithRouterClassify} from "../util/jump";
import "../styles/expert-list.css";
import { Empty, Spin } from 'antd';
import { Breadcrumb } from 'antd';

class ExpertItem extends React.Component {
    static contextType = NavigationContext; // 设置 contextType 以访问 navigate

    handleClick = (data) => {
        const { navigate } = this.context;
        // jump (data.type, data.link, data.id, navigate);
        const {channelId, channelName} = this.props;
        jumpWithRouter(data.type, data.link, data.id, data.title ,navigate, channelId ,channelName);
    }
    render() {
      const { article } = this.props;
      if (!article) {
        return null;
      }
      return (
        <div className="article-item" onClick={()=>this.handleClick(article)}>
             <div className="article-img-container">
          <img src={article.img} alt='' className="article-img" />
          </div>
          <div className="article-info">
       
          <h3 className="article-title">{article.name || ""} {article.position || ""}</h3>
          <p className="article-desc">{article.content || ""}</p>
            {/* <p className="article-time">发布时间: {article.created_at || ""}</p> */}
          </div>
        
        </div>
      );
    }
}
  
class ExpertList extends React.Component {
  static contextType = NavigationContext; // 设置 contextType 以访问 navigate

    constructor(props) {
        super(props);
        this.state = {
          articles: [],
          breadcrumb: [],
          currentPage: 1, 
          totalPages: 1,
          pageSize:10, 
          loading: true,
          error: null
        };
      }
    
      async componentDidMount() {
        try {
          await this.fetchArticles(this.state.currentPage);
        } catch (error) {
          console.error('Error occurred:', error);
        }
      }
    
      fetchArticles = async (page) => {
        try {
          console.log("this.props.channelId = ",this.props.channelId);
          const result = await fetchAndProcessData(apiExpertListUrl(this.state.pageSize, page), this.setState.bind(this));
         
          this.setState({
            articles: result.list, 
            breadcrumb: result.breadcrumb, 
            totalPages: Math.ceil(result.total / 10), 
          });
        } catch (error) {
        }
      };
    
      handleNextPage = () => {
        if (this.state.currentPage < this.state.totalPages) {
          const nextPage = this.state.currentPage + 1;
          this.setState({ currentPage: nextPage, loading: true }, () => {
            this.fetchArticles(nextPage);
          });
        }
      };
    
      handlePrevPage = () => {
        if (this.state.currentPage > 1) {
          const prevPage = this.state.currentPage - 1;
          this.setState({ currentPage: prevPage, loading: true }, () => {
            this.fetchArticles(prevPage);
          });
        }
      };
    
      handlePageClick = (page) => {
        this.setState({ currentPage: page, loading: true }, () => {
          this.fetchArticles(page);
        });
      };
    
      handleClick = (pathname) => {
        const { navigate } = this.context;
        // jump (data.type, data.link, data.id, navigate);
        // const {channelId, channelName} = this.props;
        if(pathname == '/home'){
          window.location.href=('/home');
        }
        jumpWithRouterClassify(0,pathname,navigate);
    }
      render() {
        const { articles, breadcrumb,currentPage, totalPages, loading, error } = this.state;
        const {channelId, channelName} = this.props;

        const items = breadcrumb.map((item, index) => ({ 
          title: <div onClick={()=>this.handleClick(item.pathname)}>{item.title}</div>,  
          key: item.pathname,
        }));  
        console.log("breadcrumb = ",breadcrumb);
        console.log("articles = ",articles);
        // TODO ,error 和 loading 需要提出来，后面复用
        if (loading) return <div style={{margin:'auto',marginTop:'50px',height:'900px'}}>    <Spin size="large" /></div>;
        if (error) return <div>Error: {error.message}</div>;
        // if(articles.length == 0 )return <div style={{height:'300px',margin:'auto',marginTop:'50px'}}> <Empty description='暂无数据' /> </div>;
        return (
          <>       
          <Breadcrumb className="breadcrumb"
          items={items}
          /> 
          {articles.length == 0 ? <div style={{height:'300px',margin:'auto',marginTop:'50px'}}> <Empty description='暂无数据' /></div>:

          <div className="article-list-container">
             
            {articles.map(article => (
              <ExpertItem key={article.id} article={article} channelId={channelId} channelName={channelName}/>
            ))}
            <div className="pagination">
              <button onClick={this.handlePrevPage} disabled={currentPage === 1}>
              &lt;
              </button>
              {[...Array(totalPages)].map((_, i) => (
                <button
                  key={i}
                  onClick={() => this.handlePageClick(i + 1)}
                  className={currentPage === i + 1 ? 'active' : ''}
                >
                  {i + 1}
                </button>
              ))}
              <button onClick={this.handleNextPage} disabled={currentPage === totalPages}>
              &gt;
              </button>
            </div>
          </div>
          }
          </>
        );
      }
    }


export default ExpertList;