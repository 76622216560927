// 用来封装二级跳转，跳转到详情或者外链或者不跳转

// 跳转函数
export function jump(type, link, articleId, navigate) {
  if (type === 1) {
    // 跳转到外链
    window.open(link, '_blank');
  } else if (type === 0) {
    // 跳转到文章详情页
    navigate(`/articleDetail/${articleId}`);
  } 
}

// 跳转详情页增加路由
export function jumpWithRouter(type, link, articleId, articleTitle, navigate, channelId, channelName) {
  
  if (type === 1) {
    // 跳转到外链
    window.open(link, '_blank');
  } else if (type === 0) {
    // 跳转到文章详情页
    navigate(`/articleDetail/${articleId}`, {state: {channelId, channelName, articleTitle}});
  } 
}
// 跳转分类页
export function jumpWithRouterClassify(type, pathname,  navigate) {
  // console.log(pathname);

  // if (type === 1) {
  //   // 跳转到外链
  //   window.open(pathname, '_blank');
  // } else if (type === 0) {
    // 跳转到文章详情页
    navigate(pathname);
  // } 
}
// 只跳转详情页，不传入任何额外信息
export function jumpToArticleList(navigate, channelId) {
  navigate(`/search/${channelId}`); // 使用 channelId 进行导航
}

// 传入 channelId 和 name 的跳转详情页
export function jumpToArticles(navigate, channelId, channelName) {
    navigate(`/search/${channelId}`,{state:{channelId, channelName}}); // 使用 channelId 进行导航
}
