import * as model from './Model';

export class DataStore {
    constructor() {
      this.channels = []; // 存储一级频道
      this.channelMap = new Map(); // 用于快速查找一级频道及其二级频道
    }
  
    // 从 API 数据中初始化 DataStore
    initializeChannels(data) {
     const datas = [  
  {  
    id: 1,  
    title: "关于联盟",  
    subChannels: [  
      { id: 10014, title: "联盟简介", article_type: 0, article_id: 0 },  
      { id: 10015, title: "联盟章程", article_type: 0, article_id: 0 },  
      { id: 10016, title: "组织架构", article_type: 1, article_id: 157 },  
      { id: 10017, title: "资质荣誉", article_type: 0, article_id: 0 },  
      { id: 10018, title: "专家顾问", article_type: 0, article_id: 0 }  
    ],  
    type: 1  
  },  
  {  
    id: 2,  
    title: "联盟动态",  
    subChannels: [  
      { id: 10019, title: "联盟要闻", article_type: 0, article_id: 0 },  
      { id: 10020, title: "党建引领", article_type: 0, article_id: 0 },  
      { id: 10021, title: "交流合作", article_type: 0, article_id: 0 }  
    ],  
    type: 1  
  },  
  {  
    id: 3,  
    title: "政策资讯",  
    subChannels: [  
      { id: 10022, title: "部委资讯", article_type: 0, article_id: 0 },  
      { id: 10023, title: "地方资讯", article_type: 0, article_id: 0 },  
      { id: 10024, title: "行业资讯", article_type: 0, article_id: 0 }  
    ],  
    type: 1  
  },  
  {  
    id: 4,  
    title: "科技标准",  
    subChannels: [  
      { id: 10025, title: "成果评价", article_type: 0, article_id: 0 },  
      { id: 10026, title: "科技推广", article_type: 0, article_id: 0 },  
      { id: 10027, title: "联盟标准", article_type: 0, article_id: 0 }  
    ],  
    type: 1  
  },  
  {  
    id: 5,  
    title: "咨询服务",  
    subChannels: [  
      { id: 10028, title: "政策研究", article_type: 0, article_id: 0 },  
      { id: 10029, title: "课题研究", article_type: 0, article_id: 0 },  
      { id: 10030, title: "规划咨询", article_type: 0, article_id: 0 },  
      { id: 10031, title: "项目咨询", article_type: 0, article_id: 0 }  
    ],  
    type: 1  
  },  
  {  
    id: 6,  
    title: "会议培训",  
    subChannels: [  
      { id: 10032, title: "会议会展", article_type: 0, article_id: 0 },  
      { id: 10033, title: "专题活动", article_type: 0, article_id: 0 },  
      { id: 10034, title: "行业培训", article_type: 0, article_id: 0 }  
    ],  
    type: 1  
  },  
  {  
    id: 7,  
    title: "会员专区",  
    subChannels: [  
      { id: 10035, title: "会员名录", article_type: 0, article_id: 0 },  
      { id: 10036, title: "会员动态", article_type: 0, article_id: 0 },  
      { id: 10037, title: "会员权益", article_type: 0, article_id: 0 },  
      { id: 10038, title: "入会指南", article_type: 0, article_id: 0 }  
    ],  
    type: 1  
  }  
];  
  
// 你可以使用 console.log 来检查数据  
// console.log(datas);
      
        this.channels = datas;
        // this.channelMap.clear();
        this.channelMap = datas;
        console.log("initializeChannels ", this.channelMap);

    }
  
    // 获取一级频道
    getChannels() {
      return  [  
        {  
          id: 1,  
          title: "关于联盟",  
          subChannels: [  
            { id: 10014, title: "联盟简介", article_type: 0, article_id: 0 },  
            { id: 10015, title: "联盟章程", article_type: 0, article_id: 0 },  
            { id: 10016, title: "组织架构", article_type: 1, article_id: 157 },  
            { id: 10017, title: "资质荣誉", article_type: 0, article_id: 0 },  
            { id: 10018, title: "专家顾问", article_type: 0, article_id: 0 }  
          ],  
          type: 1  
        },  
        {  
          id: 2,  
          title: "联盟动态",  
          subChannels: [  
            { id: 10019, title: "联盟要闻", article_type: 0, article_id: 0 },  
            { id: 10020, title: "党建引领", article_type: 0, article_id: 0 },  
            { id: 10021, title: "交流合作", article_type: 0, article_id: 0 }  
          ],  
          type: 1  
        },  
        {  
          id: 3,  
          title: "政策资讯",  
          subChannels: [  
            { id: 10022, title: "部委资讯", article_type: 0, article_id: 0 },  
            { id: 10023, title: "地方资讯", article_type: 0, article_id: 0 },  
            { id: 10024, title: "行业资讯", article_type: 0, article_id: 0 }  
          ],  
          type: 1  
        },  
        {  
          id: 4,  
          title: "科技标准",  
          subChannels: [  
            { id: 10025, title: "成果评价", article_type: 0, article_id: 0 },  
            { id: 10026, title: "科技推广", article_type: 0, article_id: 0 },  
            { id: 10027, title: "联盟标准", article_type: 0, article_id: 0 }  
          ],  
          type: 1  
        },  
        {  
          id: 5,  
          title: "咨询服务",  
          subChannels: [  
            { id: 10028, title: "政策研究", article_type: 0, article_id: 0 },  
            { id: 10029, title: "课题研究", article_type: 0, article_id: 0 },  
            { id: 10030, title: "规划咨询", article_type: 0, article_id: 0 },  
            { id: 10031, title: "项目咨询", article_type: 0, article_id: 0 }  
          ],  
          type: 1  
        },  
        {  
          id: 6,  
          title: "会议培训",  
          subChannels: [  
            { id: 10032, title: "会议会展", article_type: 0, article_id: 0 },  
            { id: 10033, title: "专题活动", article_type: 0, article_id: 0 },  
            { id: 10034, title: "行业培训", article_type: 0, article_id: 0 }  
          ],  
          type: 1  
        },  
        {  
          id: 7,  
          title: "会员专区",  
          subChannels: [  
            { id: 10035, title: "会员名录", article_type: 0, article_id: 0 },  
            { id: 10036, title: "会员动态", article_type: 0, article_id: 0 },  
            { id: 10037, title: "会员权益", article_type: 0, article_id: 0 },  
            { id: 10038, title: "入会指南", article_type: 0, article_id: 0 }  
          ],  
          type: 1  
        }  
      ];
    }
  
    // 根据一级频道的 ID 获取二级频道
    getSubChannelsById(id) {
      const channel = this.channelMap.get(id);
      return channel ? channel.getSubChannels() : [];
    }

    getChannelNameById (id) {
      console.log("getChannelNameById channelId = ",id);
      console.log("this.channels = ",this.channels);
      const channel = this.channels.find(channel => channel.id == id);
      console.log("getChannelNameById channel = ",channel);
      return channel ? channel.title : null; 
    }
}

// 导出单例实例
const instance = new DataStore();
export default instance;