// @hehzicheng
import React, { useState } from "react";
import '../styles/topper.css';
import { useNavigate } from 'react-router-dom';

import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Select, Space } from 'antd';
import { jumpToArticles } from "../util/jump";
import { NavigationContext } from "../NavigationContext";


const Topper = () => {

  const navigate = useNavigate();
  function handleSearch(value) {
    if (!value.length) return
    navigate("/search?keyword=" + value)
  }

  return (
    <div>
      <div className="top-search">
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: 1000,
          height: '100%',
          margin: '0 auto'
        }}>
          <img className="logo" src='/image/logo.png' style={{
            width: 410,
            cursor: 'pointer',
          }}
            onClick={() => { navigate('/home') }}
          />
          <Input.Search
            className="custom-search-input"
            placeholder="搜资讯"
            // value={this.state.searchValue}
            // onChange={this.handleSearchChange}
            onSearch={handleSearch}
            allowClear
            style={{
              maxWidth: 260
            }}
          />
        </div>

      </div>
      <img className="top" src='https://www.zgeia.cn/images/bg1.png' />
      <div className="bg-center" >
        <div className="sologan">
          <span className="sologan-text1">践行生态文明</span>
          <span className="sologan-text2">守护绿水青山</span>
          {/* <img className="ljgd" src='/image/ljgd.png' /> */}
        </div>
      </div>
    </div>
  );

}
export default Topper;