import { fetchAndProcessData, fetchAndProcessHomeData,API_HOME_SIX_MODULE,API_HOME_THREE_ARTICLE_MODULE,API_HOME_COUNCIL,API_HOME_EXPERT} from '../api/Api'
import React from 'react';
import Banner from "../components/Banner";
import Footer from '../components/Footer';
import ArticleGroup from '../components/ArticleGroup';
import ArticleImage from "../components/ArticleImage";
import CouncilImage from "../components/CouncilImage";
import ExpertGroup from "../components/ExpertGroup";
import { ProCard} from '@ant-design/pro-components';
import  '../styles/home.css';
import '../styles/footer.css';
import { Carousel, Space } from 'antd';
import { CheckCard } from '@ant-design/pro-components';
import { NavigationContext } from "../NavigationContext";
import { jumpWithRouterClassify} from "../util/jump";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      loading: true,
      error: null,
      dataSixModule:{list: []}, // 默认设置为这个，保证总有 list 属性
      loadingSixModule:true,
      errorSixModule:null,
      dataThreeArticleModule:{list:[]},
      loadingThreeArticleModule:true,
      errorThreeArticleModule:null,
    };
  }
  static contextType = NavigationContext; // 设置 contextType 以访问 navigate

  async componentDidMount() {
    console.log('Home component mounted');
    // 请求首页
    await fetchAndProcessHomeData(this.setState.bind(this));

    // 请求 6 模块
    await fetchAndProcessData(API_HOME_SIX_MODULE, this.setState.bind(this), "SixModule");
  
    // 请求 3 个文章
    await fetchAndProcessData(API_HOME_THREE_ARTICLE_MODULE,this.setState.bind(this),"ThreeArticleModule");
    //请求理事单位
    await fetchAndProcessData(API_HOME_COUNCIL,this.setState.bind(this),"CouncilList");

    //请求专家委员会
    await fetchAndProcessData(API_HOME_EXPERT,this.setState.bind(this),"ExpertList");
  }


  componentDidUpdate(prevProps, prevState) {
    // 检查状态是否已更新
    if (prevState.data !== this.state.data) {
        console.log('Data has been updated', this.state.data);

        // 只有在有数据并且没有错误时，才更新 Tab 数据
        if (this.state.data && this.props.updateTabs) {
            console.log("更新 tab 数据，", this.state.data);
            if (this.state.data.channels) {
              this.props.updateTabs(this.state.data.channels);
              console.log("this.state.data.channels", this.state.data.channels);
            }
        }
    }

    // 处理错误
    if (prevState.error !== this.state.error && this.state.error) {
        console.error("Error fetching home data:", this.state.error);
    }
}
handleClick = (pathname) => {
  const { navigate } = this.context;
  jumpWithRouterClassify(0,pathname,navigate);
}
  redirectTo(url) {
    window.location.href = url;
  }
  render() {
    const { data, loading, error } = this.state;

    if (loading) return <CheckCard loading />;
    if (error) return <div>Error: {error.message}</div>;

    if (!data) return null;

    // 处理 6 模块的数据
    const {dataSixModule, loadingSixModule, errorSixModule } = this.state;
    const listSixModule = (dataSixModule && dataSixModule.list) || []; // 这里是拿到 API 返回的 list，但是还需要把 classify_one这种去掉

    // 处理 3文章 模块的数据
    const {dataThreeArticleModule, loadingThreeArticleModule, errorThreeArticleModule } = this.state;
    const listThreeModule = (dataThreeArticleModule && dataThreeArticleModule.list) ? dataThreeArticleModule.list.slice(0, 3):[]; // 这里是拿到 API 返回的 list，但是还需要把 classify_one这种去掉

    // 因为 6 模块盒 3 模块要分左边和右边，所以做一个拆分
    const leftSixModules = listSixModule.slice(0, Math.ceil(listSixModule.length / 2));
    const rightSixModules = listSixModule.slice(Math.ceil(listSixModule.length / 2));
    const leftThreeModules = listThreeModule.slice(0, Math.ceil(listThreeModule.length / 2));
    const rightThreeModules = listThreeModule.slice(Math.ceil(listThreeModule.length / 2));

    const {dataCouncilList, loadingCouncilList, errorCouncilList } = this.state;
    const CouncilList = (dataCouncilList && dataCouncilList.list) ? dataCouncilList.list.slice(0, 10):[]; // 这里是拿到 API 返回的 list

    const {dataExpertList, loadingExpertList, errorExpertList } = this.state;
    const ExpertList = (dataExpertList && dataExpertList.list) ? dataExpertList.list.slice(0, 4):[]; // 这里是拿到 API 返回的 list

    
    return (
      <div>

        <div className="container">
        {/* -------------- 左侧 ---------------- */}
          <div className='left-side'>
            <Banner banners={data.banner || []} />
            {/* 左侧六模块 */}
            <div>
            {
              leftSixModules.map(
                  (item, index) => (
                    <React.Fragment key={index}>
                      {Object.keys(item).map(
                          (key) => {
                              const group = item[key];
                              return <ArticleGroup key={key?key:index} groupData={group || []} /> 
                          }
                      )}      
                      {index===0 && (
                        <div className='left-three-articles-container'>
                        {/* 左侧三文章 */}
                        {
                        leftThreeModules.map(
                          (item, index) => (
                              <ArticleImage key={item.id?item.id:index} data={item} />
                          )
                          )
                        }     
                        </div>
                      )}
                      </React.Fragment>
                  )
              )   
            } 
            </div>
          </div>
        {/* -------------- 右侧 ---------------- */}
          <div className='right-side'>
              {/* 右侧六模块 */}
           <div>
            {
              rightSixModules.map(
                  (item, index) => (
                    <React.Fragment key={index}>
                      {Object.keys(item).map(
                          (key) => {
                              const group = item[key];
                              return <ArticleGroup key={key?key:index} groupData={group || [] } isFirstRight={index===0}/> 
                          }
                      )}
                      {index===0 && (
                         <div className="right-three-articles-container">
                         {/* 右侧三文章 */}
                         {
                           rightThreeModules.map(
                             (item, index) => (
                                 <ArticleImage key={item.id?item.id:index} data={item} />
                             )
                           )
                         }     
                         </div>
                      )}
                       {/* ------------- 专家委员会 ---------------*/}
                        {index===1 && (
                          <ProCard
                          className='expert'
                            bordered={false}
                          >
                              <div className='expert-top'>
                                <div className='expert-top-text'>专家委员会</div>
                              </div>
                            <Carousel autoplay
                                        dots={false}

                            >
                            {
                                ExpertList.map(
                                  (item, index) => (
                                      <ExpertGroup key={item.id?item.id:index} data={item} />
                                  )
                                )
                              }
                            </Carousel>
                            <div className='expert-footer'  onClick={()=>this.handleClick('/expertList')}>了解更多</div>
                          </ProCard>) }
                      </React.Fragment>
                  )
              )
            } 
            </div>
          </div>
        </div>
        {/* -------------理事单位 ---------------*/}
        <ProCard
          style={{ maxWidth: '100%' }}
        >
          <div className='council'  onClick={()=>this.handleClick('/council')}>
            <div style={{
              display: 'flex',
              gap: 2,
              alignItems: 'center',
            }}>
              <img className='council-img' src='https://www.zgeia.cn/images/council.png' />
              <p className='council-title'>理事单位</p>
            </div>
            <Space size={[82, 32]} wrap style={{
              justifyContent: 'space-between',
              paddingBottom: 32
            }}>
              {
                CouncilList.map(
                  (item, index) => (
                    <CouncilImage key={item.id ? item.id : index} data={item} />
                  )
                )
              }
            </Space>
          </div>
        </ProCard>



      </div>
    );
  }
}

export default Home;
