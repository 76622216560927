import axios from "axios";
import { isEmptyObject, isEmptyString } from "../util/empty";
import { Channel } from "../datastore/Model";
import dataStore from "../datastore/Datastore";


const API_BASE_URL = "https://www.zgeia.cn";

export const API_CONFIG_URL = "api/config"; // 配置文件
export const API_HOME_URL = "api/home"; // 首页
export const API_HOME_SIX_MODULE = "api/home_module"; // 首页 6 个频道模块
export const API_HOME_THREE_ARTICLE_MODULE = "api/home_article"; // 首页 3 个文章模块
export const API_HOME_COUNCIL = "api/council"; // 理事单位
export const API_HOME_EXPERT = "api/expertlist?page=1&pageSize=4"; // 理事单位

export const apiArticleDetailUrl = (article_id) => (`api/detail?id=${article_id}`); // 文章详情页 url
export const apiArticleListUrl = (classify_id, page_size = 10,page = 1) => (`api/classify?classify_id=${classify_id}&pageSize=${page_size}&page=${page}`); // 文章列表 list
export const apiExpertListUrl = ( page_size = 10,page = 1) => (`api/expert?pageSize=${page_size}&page=${page}`); // 文章列表 list
export const apiSearchUrl = (keyword, page_size = 10,page = 1) => (`api/search?keyword=${keyword}&pageSize=${page_size}&page=${page}`); // 搜索文章列表 list
export const apiCouncilUrl = ( page_size = 10,page = 1) => (`api/councilList?pageSize=${page_size}&page=${page}`); 

// const API_
// const API_

// 获取业务错误信息的函数
const getErrorMessage = (status) => {
    switch (status) {
      case 1000:
        return '请求失败';
      case 1001:
        return '参数错误';
      case 1002:
        return '数据库错误';
      default:
        return '未知错误';
    }
};

export const fetchData = async(endpoint) => {
    let errorCode
    let errorMessage
    let businessData
    try {
        const response = await axios.get(`${API_BASE_URL}/${endpoint}`);
        console.log('fetchData called url=',endpoint);
        console.log('response = ',response);

        // 网络处理 3 基础：(1)：如果正确那么不给任何错误码直接返回 response.data 的 data 数据结构；(2)如果错误，需要给出错误提示 +错误码，便于修改错误 (3)如果是空数据，也返回错误，错误码为-1
        // 网络错误处理
        if (response.status !== 200) {
            errorCode = response.status;
            errorMessage = response.statusText;
            throw new Error(`网络错误: 错误码[${errorCode}] ${errorMessage}`);
        } 
    
        // 业务错误处理
        const { status, msg, data } = response.data;
        if (status !== 0) {
            errorCode = status;
            errorMessage = isEmptyString(msg)?getErrorMessage(status):msg;
            throw new Error(`业务错误: 错误码[${errorCode}] ${errorMessage}`)
        }
          
        // 空数据处理
        if (isEmptyObject(data)) {
            errorCode = -1;
            errorMessage = '空数据';
            throw new Error(`数据错误: 错误码[${errorCode}] ${errorMessage}`);
        } 
        businessData = data;

    } catch (error) {
        throw error
    }
    console.log('请求接口成功，businessData = ',businessData);

    return businessData
}

// 通用的 API 请求加状态处理
export const fetchAndProcessData = async (url, setState, key="") => {
    try {
      const result = await fetchData(url); // 从 API 获取数据
      // 设置组件状态
      setState({
        [`data${key}`]: {
          ...result,
        },
        [`loading${key}`]: false,
        [`error${key}`]:null,
      });
      return result;
    } catch (error) {
      setState({
        [`error${key}`]: error,
        [`loading${key}`]: false,
      });
      throw error; // 抛出错误
    }
};

// 请求首页数据
export const fetchAndProcessHomeData = async (setState) => {
    try {
      const result = await fetchData(API_HOME_URL); // 从 API 获取数据
      console.log('首页接口原始返回为：', result);
      const processedData = processNavigationData(result.navigation); // 处理数据
      console.log('首页接口处理完一二级频道数据为：', processedData);
      console.log('一级频道为：',processedData.channels);
      console.log('二级频道为：',processedData.channelMap);

      // 保存数据到 DataStore
      dataStore.initializeChannels(processedData);
      // 设置组件状态
      setState({
        data: {
          ...result,
          channels: processedData.channels,
          channelMap: processedData.channelMap,
        },
        loading: false,
        error:null,
      });
    } catch (error) {
      setState({
        error: error,
        loading: false,
      });
    }
  };

// 用于处理导航数据的函数
const processNavigationData = (navigationData) => {
    const channels = [];
    const channelMap = new Map();
  
    navigationData.forEach(item => {
      const channel = new Channel(item.classify_id, item.title, item.below, item.type);
      channels.push(channel);
      channelMap.set(channel.id, channel);
    });
  
    return { channels, channelMap };
};