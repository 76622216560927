import React from "react";
import { fetchAndProcessData , apiArticleDetailUrl} from "../api/Api";
import "../styles/article-detail.css";
import { NavigationContext } from "../NavigationContext";

import { jumpWithRouterClassify} from "../util/jump";
import { Breadcrumb } from 'antd';
class ArticleDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      articleId:null,
      data: null,
      loading: true,
      error: null,
    };
    console.log("ArticleDetail constructor");
  }
  static contextType = NavigationContext; // 设置 contextType 以访问 navigate

  async componentDidMount() {
    console.log("ArticleDetail componentDidMount this.state.articleId = ",this.props.articleId);

    if (this.props.articleId) {
        await fetchAndProcessData(apiArticleDetailUrl(this.props.articleId), this.setState.bind(this));
    }
  }
  handleClick = (pathname) => {
    const { navigate } = this.context;
    if(pathname == '/home'){
      window.location.href=('/home');
      pathname = '/home';
    }
    jumpWithRouterClassify(0,pathname,navigate);
}
  render () {
    const { data, error, loading } = this.state;
    if (!data) return null
    
    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;

    const { title, content, created_at,classify,breadcrumb} = data;
    console.log("Article Detail =",data);

    const {channelId, channelName} = this.props;
    console.log("Article Detail channel info = ",channelId,channelName);
    // TODO @hezhicheng
    const items = breadcrumb.map((item, index) => ({ 
      title: <div onClick={()=>this.handleClick(item.pathname)}>{item.title}</div>,  
      key: item.pathname,
    }));  
    return (
      <div className="article-container">                
                <ul className="article"> 
                 {classify.map(classifys => (
                    classifys.type == 0 ?
                        <li  onClick={()=>this.handleClick('/articleList/'+classifys.classify_id)} className="article-channel" key={classifys.id}> {classifys.title}</li>
                        :
                        <li  style={{backgroundColor:'#5B8C00'}} onClick={()=>this.handleClick('/articleList/'+classifys.classify_id)} className="article-channel" key={classifys.id}> {classifys.title}</li>
                ))} 
                    </ul>
                  <div className="article-detail-container">
                        <Breadcrumb className="breadcrumb-article"
                        items={items}
                        /> 
                        <h1 className="title">{title || ""}</h1>
                        <p className="time">发布时间: {created_at || ""}</p>
                        <div className="content" dangerouslySetInnerHTML={{ __html: content || ""}} />
                    </div>
                  
                
            </div>
    );
  }

}
export default ArticleDetail;