// 频道
export class Channel {
    constructor(classify_id, title, below = [], type = 0) {
      this.id = classify_id;
      this.title = title;
      this.type = type;
      this.subChannels = below.map(sub => new Channel(sub.classify_id, sub.title, sub.below || [], sub.type || 0));      }

    getSubChannels () {
      return this.subChannels;
    }
}

