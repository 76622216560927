import React from 'react';
import "../styles/tab-app.css";
import { jumpWithRouterClassify} from "../util/jump";
import { NavigationContext } from "../NavigationContext";
import { Button, Dropdown, Menu,ConfigProvider } from 'antd';
class Tab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
      isLoaded:false,
      hoverTabIndex: null, 

    };
    this.tabsRef = React.createRef();
    this.isDragging = false;
    this.startX = 0;
    this.scrollLeft = 0;
    console.log("tab start");
  }
  static contextType = NavigationContext;

  handleMouseDown = (e) => {
    this.isDragging = true;
    this.startX = e.pageX - this.tabsRef.current.offsetLeft;
    this.scrollLeft = this.tabsRef.current.scrollLeft;
  };

  handleMouseMove = (e) => {
    if (!this.isDragging) return;
    const x = e.pageX - this.tabsRef.current.offsetLeft;
    const walk = (x - this.startX) * 2; // scroll-fast
    this.tabsRef.current.scrollLeft = this.scrollLeft - walk;
  };

  handleMouseUp = () => {
    this.isDragging = false;
  };
  handleTabMouseEnter = (index) => {  
    this.setState({ hoverTabIndex: index });  
  };  
  
  handleTabMouseLeave = () => {  
    this.setState({ hoverTabIndex: null }); // 清除悬停状态  
  };  
  
  componentDidMount() {
    const tabContainer = this.tabsRef.current;
    tabContainer.addEventListener('mousedown', this.handleMouseDown);
    tabContainer.addEventListener('mousemove', this.handleMouseMove);
    tabContainer.addEventListener('mouseup', this.handleMouseUp);
    this.setActiveTab(0);
    console.log("tab mounted");
  }

  componentDidUpdate(prevProps) {
    // Check if tabs data has changed
    console.log("componentDidUpdate",this.props.tabs,prevProps.tabs);
    if(!this.state.isLoaded) {
      this.setState({ isLoaded: true }); // Update isLoaded status
      this.setActiveTab(0);
      return;
    }
    if (this.props.tabs !== prevProps.tabs) {
      if (this.props.onTabChange) {
        this.props.onTabChange(this.state.activeTab);
        console.log("this.props.onTabChange(this.state.activeTab);",this.state.activeTab);
      }
    }
  }

  componentWillUnmount() {
    const tabContainer = this.tabsRef.current;
    tabContainer.removeEventListener('mousedown', this.handleMouseDown);
    tabContainer.removeEventListener('mousemove', this.handleMouseMove);
    tabContainer.removeEventListener('mouseup', this.handleMouseUp);
  }

  setActiveTab = (index) => {
    console.log("ActiveTab index = ",index);
    this.setState({ activeTab: index });
    if (this.props.onTabChange) {
        this.props.onTabChange(index);
      }
  };

  setActiveTab = (index) => {
    console.log("ActiveTab index = ",index);
    this.setState({ activeTab: index });
    if (this.props.onTabChange) {
        this.props.onTabChange(index);
      }
  };
  tabHandleClick = (pathname,index) => {
    const { navigate } = this.context;
    // console.log(pathname);
    if(pathname == '/articleList/0'){
      window.location.href=('/home');
      pathname = '/home';
    }
    this.setState({ activeTab: index });
    if (this.props.onTabChange) {
        this.props.onTabChange(index);
      }
    jumpWithRouterClassify(0,pathname,navigate);
  }

  handleClick = (pathname) => {
    const { navigate } = this.context;
    // console.log(pathname);
    if(pathname == '/articleList/0'){
      window.location.href=('/home');
      pathname = '/home';
    }

    jumpWithRouterClassify(0,pathname,navigate);
  }
  render() {

    const { activeTab , hoverTabIndex } = this.state;

    // const activeTab = 4;
    var currentPath = window.location.pathname; 
    currentPath = currentPath.substring(1);
    const pathParts = currentPath.split('/');  
    const id = pathParts[pathParts.length - 1]; 
    if(activeTab == 0 && pathParts != 'home' && pathParts != ''){
      this.setState({  
        activeTab: id
      });
    }


    // 数据传入
    const tabs = this.props.tabs;

    // 通过父组件传递样式
    const { containerClass, tabsClass, activeTabClass, tabClass } = this.props;
    return (
      <div className={containerClass || ""}>
        <div className={tabsClass || ""} ref={this.tabsRef}>
          {tabs.map((tab, index) => (
          tab.type == 1 ?       
          
            <Dropdown  
              placement="bottom"  
              trigger={['hover']}  
            
              overlay={  

                <Menu>  
                  {tab.subChannels.map((subItem, subIndex) => (  
                    <Menu.Item  
                      key={subItem.id}  
                      onClick={()=>this.handleClick('/articleList/'+tab.id)}
                      className='below'
                      // className={tab.id === subItem.id ? 'below' : ''}  
                      onClick={() => this.tabHandleClick('/articleList/' + subItem.id, index)}  

                      >  
                      
                      {subItem.title}  
                    </Menu.Item>  
                  ))}  
                </Menu>  
              }  
            >  
          <a  
            style={{ backgroundColor: 'transparent', border: 'none', color: 'inherit' }}  
            onClick={() => this.tabHandleClick('/articleList/' + tab.id, index)}  
          >  
            <div  
              className={`${tabClass} ${index == activeTab ? (activeTabClass || "") : ""}`}  
            >  
              {tab.title}  
            </div>  
          </a>  
           </Dropdown>  

            : 
            <div
              key={tab.id || index}
              // className={index === activeTab ? (activeTabClass || "") : tabClass}
              className={`${tabClass} ${tab.id == activeTab ? (activeTabClass || "") : ""}`}
              onMouseEnter={() => this.setActiveTab(index)} 
              onClick={()=>this.handleClick('/articleList/'+tab.id)}
              >

              {tab.title}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default Tab;
