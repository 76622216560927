import { createBrowserHistory } from 'history';

class RouteHistory {
  constructor() {
    this.history = [];
    this.browserHistory = createBrowserHistory();

    // 监听路由变化
    this.unlisten = this.browserHistory.listen((location) => {
      this.addLocation(location);
    });
  }

  addLocation(location) {
    if(location.location) {
      // 将新的位置添加到历史记录中
      const newLoc = location.location;
      this.history.push(newLoc);
      console.log("RouteHistory addLocation = ", this.history);
      console.log("RouteHistory newLoc = ", newLoc);


      // 找到最后一个 '/home' 的索引
      const homeArray = ['/home', '/articleList/1', '/articleList/2', '/articleList/59', '/articleList/60', '/articleList/61', '/articleList/62', '/articleList/63'];
      let homeIndex = -1;
      for (const homePath of homeArray) {
        console.log("RouteHistory homePath in for =",homePath);
        if (newLoc.pathname == homePath) {
          homeIndex = this.history.map(loc => loc.pathname).lastIndexOf(homePath);
          console.log("RouteHistory find homeIndex =",homeIndex);
        }

        if (homeIndex !== -1) {
          break;  
        }
      }
      if (homeIndex !== -1) {
          // 从最后一个 '/home' 开始重置历史记录，确保包含 '/home'
          this.history = this.history.slice(homeIndex); 
          console.log("RouteHistory 裁剪 home",this.history);
      }
    }
}

  getPathArray() {
    return this.history.map(loc => loc.pathname);
  }

  // 移除监听器
  stopListening() {
    if (this.unlisten) {
      this.unlisten();
    }
  }
}

// 创建 RouteHistory 实例
const routeHistory = new RouteHistory();

export { routeHistory };
export default routeHistory.browserHistory;
