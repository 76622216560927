import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import Home from './pages/Home';
import Tab from './components/Tab';
import Topper from "./components/Topper";
import Footer from './components/Footer';
import dataStore from './datastore/Datastore';
import ArticleLists from './pages/ArticleLists';
import ExpertLists from './pages/ExpertLists';
import Search from './pages/Search';
import Council from './pages/Councils';
import ArticleDetail from './pages/ArticleDetail';
import { NavigationProvider } from './NavigationContext';
import { fetchData,API_CONFIG_URL } from './api/Api'
import { useRequest } from 'ahooks';
import { HomeDataContext } from './context'
import browserHistory from './util/route-history'
import { routeHistory } from './util/route-history'
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import '../src/styles/topper.css';


function App() {

  const { data } = useRequest(() => { return fetchData(API_CONFIG_URL) })

  return (

    <HistoryRouter history={browserHistory}>
      <NavigationProvider>
        <HomeDataContext.Provider value={data}>
          <Routes>
            <Route path="/*" element={<TabWrapper />} />
          </Routes>
        </HomeDataContext.Provider>
      </NavigationProvider>
    </HistoryRouter>

  );
}

// TabWrapper 函数组件
function TabWrapper() {
  console.log("TabWrapper component loaded");

  const navigate = useNavigate();

  const [tabsData, setTabsData] = React.useState(initializeTabsData());

  function initializeTabsData() {
    const channels = dataStore.getChannels();
    console.log("Channels from dataStore:", channels);
    return [{ id: 0, title: '首页' }, ...channels];
  }

  function handleTabChange(index) {
    console.log(index)
    // if (index == 0) {
    //   navigate('/home');
    //   console.log("Channels from dataStore:",index);
    // } 
    var currentPath = window.location.pathname; 
    // currentPath = currentPath.substring(1);
          console.log("Channels from dataStore:",currentPath);

    if (currentPath == '/') {
      navigate('/home');
      console.log("Channels from dataStore:",index);
    } 
    // console.log("Channels from dataStore:",pathParts);
      console.log("dataStore.getChannels = ", dataStore.getChannels());
      const selectedChannel = dataStore.getChannels()[index - 1]; // 导航去这个 ArticleList 页面
      if (selectedChannel) {
        const channelId = selectedChannel.id;
        console.log("selectedChannel = ", selectedChannel);
        const channelName = selectedChannel.title; // 获取频道名称
        navigate(`/articleList/${channelId}`, { state: { channelId, channelName } }); // 使用 channelId 进行导航
      } else {
        console.error(`Invalid channel index: ${index}`);
      }
    // }
  }

  function updateTabs(newTabs) {
    setTabsData([{ id: 0, title: '首页' }, ...newTabs]);
  }

  return (
    <div>
      <Topper />
      <Tab tabs={tabsData} onTabChange={handleTabChange} containerClass="tab-container-app" tabsClass="tabs-app" tabClass="tab-app" activeTabClass="active-app" />
      <Routes>
        <Route path="/home" element={<Home updateTabs={updateTabs} />} />
        <Route path="/articleList/:channelId" element={<ArticleListsWithParams />} />
        <Route path="/expertList" element={<ExpertListsWithParams />} />
        <Route path="/articleDetail/:articleId" element={<ArticleDetailWithParams />} />
        <Route path="/search" element={<SearchWithParams />} />
        <Route path="/council" element={<CouncilWithParams />} />
        {/* 可以添加其他路由*/}
      </Routes>
      <Footer />
    </div>
  );
}


function ArticleListsWithParams() {
  const { channelId } = useParams();
  const location = useLocation();
  const channelName = location.state?.channelName;

  return <ArticleLists key={channelId} channelId={channelId} channelName={channelName || ""} />;
}
function ExpertListsWithParams() {
  const { channelId } = useParams();
  const location = useLocation();
  const channelName = location.state?.channelName;

  return <ExpertLists key={channelId} channelId={channelId} channelName={channelName || ""} />;
}

function ArticleDetailWithParams() {
  const { articleId } = useParams();
  const location = useLocation();
  const channelName = location.state?.channelName;
  const channelId = location.state?.channelId;
  const articleTitle = location.state?.articleTitle;
  return <ArticleDetail key={articleId} articleId={articleId} channelId={channelId} channelName={channelName|| ""} articleTitle={articleTitle|| ""}/>;
}

function SearchWithParams() {
  const { channelId } = useParams();
  const location = useLocation();
  const channelName = location.state?.channelName;
  return <Search key={channelId} channelId={channelId} channelName={channelName || ""} />;
}
function CouncilWithParams() {
  const { channelId } = useParams();
  const location = useLocation();
  const channelName = location.state?.channelName;
  return <Council key={channelId} channelId={channelId} channelName={channelName || ""} />;
}
export default App;
