// 检查对象是否为空
export const isEmptyObject = (obj) => {
    return obj && obj.constructor === Object && Object.keys(obj).length === 0;
};

// 检查字符串是否为空
export const isEmptyString = (str) => {
    return typeof str === 'string' && str.trim().length === 0;
};

// 检查数组是否为空
export const isEmptyArray = (arr) => {
    return arr && Array.isArray(arr) && arr.length === 0;
};

