import React from "react";
import ArticleList from "../components/ArticleList";
import { apiArticleListUrl, fetchAndProcessData } from "../api/Api";
import { NavigationContext } from "../NavigationContext";

import "../styles/articles.css"
import { jumpWithRouterClassify} from "../util/jump";
import { Breadcrumb } from 'antd';
class ArticleLists extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            classify: [],
            article:[],
            breadcrumb: [],

        };
      }
      static contextType = NavigationContext; // 设置 contextType 以访问 navigate

      async componentDidMount() {
        try {
          await this.fetchArticles(this.state.currentPage);
        } catch (error) {
          console.error('Error occurred:', error);
        }
      }
    
      fetchArticles = async () => {
        try {
          const result = await fetchAndProcessData(apiArticleListUrl(this.props.channelId,this.state.pageSize, 1), this.setState.bind(this));
          this.setState({
            classify: result.classify, 
            article: result.article, 
            breadcrumb: result.breadcrumb, 

          });
        } catch (error) {
        }
      };
    
      handleClick = (pathname) => {
        const { navigate } = this.context;
        if(pathname == '/home'){
          window.location.href=('/home');
          pathname = '/home';
        }

        jumpWithRouterClassify(0,pathname,navigate);
    }
    render () {
        const { classify ,article,breadcrumb} = this.state;
        const { channelId , channelName} = this.props;
        const items = breadcrumb.map((item, index) => ({ 
            title: <div onClick={()=>this.handleClick(item.pathname)}>{item.title}</div>,  
            key: item.pathname,
          }));  
         return (
            <div className="article-container">                
                <ul className="article"> 
                 {classify.map(classifys => (
                    classifys.type == 0 ?
                        <li  onClick={()=>this.handleClick('/articleList/'+classifys.classify_id)} className="article-channel" key={classifys.id}> {classifys.title}</li>
                        :
                        <li  style={{backgroundColor:'#5B8C00'}} onClick={()=>this.handleClick('/articleList/'+classifys.classify_id)} className="article-channel" key={classifys.id}> {classifys.title}</li>
                ))} 
                    </ul>
                    {article?  <div className="article-detail-container">
                        <Breadcrumb className="breadcrumb-article"
                        items={items}
                        /> 
                        <h1 className="title">{article.title || ""}</h1>
                        <p className="time">发布时间: {article.created_at || ""}</p>
                        <div className="content" dangerouslySetInnerHTML={{ __html: article.content || ""}} />
                    </div>:<ArticleList channelId={channelId} channelName={channelName}/>}
                  
                
            </div>
         );
    }

}
export default ArticleLists;