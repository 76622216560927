import React, { useState } from "react";
import { fetchData, fetchAndProcessData, apiSearchUrl } from "../api/Api";
import "../styles/article-list.css";
import { useLocation } from "react-router-dom";
import { useRequest } from "ahooks";
import { ProList } from '@ant-design/pro-components';
import '../styles/searchlist.css';
import { useNavigate } from "react-router-dom";
import { Button } from "antd";
const ArticleList = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const keyword = urlParams.get('keyword');

  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);


  const { run, data, loading } = useRequest(() => {
    return fetchData(apiSearchUrl(keyword, pageSize, page));
  }, {
    refreshDeps: [keyword, page, pageSize]
  })

  return <>
    <ProList
      style={{
        width: 725,
        borderColor: '#5B8C00',
        borderStyle: 'solid',
        borderWidth: '2px 0 0 0',
        margin:'60px 0 ',
        boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.15);'
      }}
      loading={loading}
      itemLayout="vertical"
      rowKey="id"
      onRow={(item) => {
      }}
      dataSource={data?.list}
      pagination={{
        onChange: (n_page, n_pageSize) => {
          if (page != n_page) {
            setPage(n_page);
          }
          if (n_pageSize != pageSize) {
            setPageSize(n_pageSize)
          }
        }
      }}
      metas={{
        title: {
          render: (_, item) => (
            <div
              onClick={() => {  
                if (item?.type === 1) {  
                  window.open(item?.link, '_blank');  
                } else if (item?.type === 0) {  
                  navigate(`/articleDetail/${item.id}`);  
                }  
              }}  
          > {item?.title} </div>
          ),
        },
        description: {
          render: (_, item) => (
            
            <>
              发布时间: {item.created_at || ""}
            </>
          ),
        },
        extra: {
          render: (_, item) => (
            <img
              width={190}
              height={126}
              // alt="logo"
              style={{
                objectFit: 'contain',
              }}
              src={item.img}
              onClick={() => {  
                if (item?.type === 1) {  
                  window.open(item?.link, '_blank');  
                } else if (item?.type === 0) {  
                  navigate(`/articleDetail/${item.id}`);  
                }  
              }}  
            />
          ),
        },
        content: {
          render: (_, item) => {
            return (
              <div
                style={{
                  maxHeight: '66px',
                  fontSize: 14,
                  lineHeight: '22px',
                  color: 'rgba(0, 0, 0, 0.45)',
                  overflow: 'hidden',
                  whiteSpace: 'collapse'
                }}
              >
                {item.desc}
              </div>
            );
          },
        },
      }}
    />
  </>


}


export default ArticleList;