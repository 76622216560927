import React from "react";
import { jump,jumpWithRouter } from "../util/jump";
import { NavigationContext } from "../NavigationContext";

class ArticleSimpleList extends React.Component {

    static contextType = NavigationContext; // 设置 contextType 以访问 navigate

    handleClick = (item) => {
        console.log("ArticleSimpleList Item clicked:", item);
        const { navigate } = this.context;
        // jump (item.type, item.link, item.id,navigate);
        jumpWithRouter(item.type, item.link, item.id, item.title ,navigate, 0 ,"首页");

    };

    render() {
        const {list} = this.props;
        console.log("ArticleSimpleList list = ",list);

        if (!Array.isArray(list)) return null;

        return (
            <div className="article-simple-list">
                <ul>
                    {list.map((item) => (
                        <li key = {item.id} onClick={() => this.handleClick(item)}>
                            {item.title}
                        </li>))
                    }
                </ul>
            </div>
        );
    };

}
export default ArticleSimpleList;