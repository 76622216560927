import React from "react";
import Tab from "./Tab";
import ArticleSimpleList  from "./ArticleSimpleList";
import "../styles/article-group.css"

class ArticleGroup extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            listData: this.props.groupData[0]?.list || [], // 初始值为第一个 tab 的数据
            currentIndex:0,
        }
        console.log("ArticleGroup constructor");
    }

    handleTabChange = (index) => {
        // 根据选中的 tab 更新 listData
        if(index == this.state.currentIndex) {
            return;
        }
        this.setState({
            listData: this.props.groupData[index].list,
            currentIndex:index,
        });
    };

    render() {
        console.log("ArticleGroup render");

        const { groupData ,isFirstRight} = this.props;
        console.log("tab groupData = ",groupData);

        return (
            <div className={!isFirstRight?'article-group-container':'article-group-container-first-right'}>
                <Tab tabs={groupData.map((item, index) => {
                        console.log("groupData",index,item);
                        return(
                            {id:(item&&item.classify_id)?item.classify_id:index, title:(item&&item.title)?item.title:""}
                        ) })}
                    onTabChange={this.handleTabChange}
                    containerClass="tab-container-article-group"
                    tabsClass="tabs-article-group"
                    tabClass="tab-article-group"
                    activeTabClass="active-article-group"
                />
                <ArticleSimpleList
                    list={this.state.listData || []} // 只显示当前选中的 tab 的 list
                />
            </div>
        );
    }

}
export default ArticleGroup;